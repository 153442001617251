import { graphql } from "gatsby";
import React, { useEffect } from "react";
import PageLayout from "../components/Layout/Page";
import Seo from "../components/Seo";
import Trip from "../modules/Trip";

const TripTemplate = (props) => {
  const modules = props?.data?.trip?.tripContent?.tripContent || [];

  useEffect(() => {
    document.body.classList.remove("nav-menu-open");
    document.body.classList.remove("header-sticky");
    document.body.classList.remove("video-modal-open");
    document.body.classList.remove("blog-single-custom");

  }, []);

  return (
    <PageLayout>
      <Seo seo={props.data.trip.seo} />
      <Trip modules={modules} location={props.location} />
    </PageLayout>
  )
}

export default TripTemplate;
export const TripById = graphql`
  query tripById ($id: String) {
    trip: wpTrip(id: { eq: $id }) {
      id
      ...seoTripFragment
      ...TripModulesFragment
    }
  }
`;