import { motion, useInView } from 'framer-motion';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const IconWithCaption = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    console.log(hasPlayed);
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  return (
    <section className="icon-list lg:py-50 py-25 bg-pattern">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView || hasPlayed ? 'show' : 'hidden'}
        variants={container}
        className="md:w-9/12 w-full m-auto"
      >
        <motion.div
          variants={item}
          className="flex flex-wrap justify-center gap-x-28 gap-y-10 desktop3:gap-x-20 smscreen2:flex-col smscreen2:pl-30 fade-ani"
        >
          {module?.data?.length > 0 &&
            module.data.map((iconWithCaptionContent) => (
              <div
                key={uuidv4()}
                className="flex flex-wrap items-center smscreen2:justify-center smscreen2:flex smscreen2:w-[70%] smscreen2:m-auto smscreen2:flex-initial xsscreen2:w-[80%]"
              >
                <div className="icon mr-15">
                  <GatsbyImage
                    image={getImage(iconWithCaptionContent.icon)}
                    alt={iconWithCaptionContent.icon.altText}
                  />
                </div>
                <div className="content">
                  {iconWithCaptionContent.heading && (
                    <p className="">{iconWithCaptionContent.heading}</p>
                  )}
                  {iconWithCaptionContent.caption && (
                    <span className="text-red-300 ">
                      {iconWithCaptionContent.caption?.trim()}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default IconWithCaption;
export const IconWithCaptionFragment = graphql`
  fragment IconWithCaptionFragment on WpTrip_Tripcontent_TripContent_IconWithCaption {
    id
    hideSection
    extraClass
    backgroundStyle
    data {
      heading
      caption
      icon {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
      }
    }
  }
`;
