import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const SliderImageWithContent = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);
  return (
    <section
      className={`zigzag lg:py-60 py-30 ${
        module?.backgroundStyle === 'background with pattern'
          ? ' bg-pattern bg-pattern-spacing'
          : ''
      }${module?.extraClass ? ' ' + module.extraClass : ''}`}
    >
      <div className="container-fluid">
        <div className="flex flex-wrap items-center fade-ani">
          <div className="lg:w-6/12 w-full">
            <div className="img-slider">
              <Swiper
                spaceBetween={0}
                loop={false}
                slidesPerView={1}
                allowTouchMove={true}
                grabCursor={true}
                speed={1000}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
              >
                {module?.images.length > 0 &&
                  module.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <GatsbyImage
                        image={getImage(image)}
                        alt={image.altText}
                        className="w-full"
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
          <div className="lg:w-6/12 w-full">
            <motion.div
              ref={ref}
              initial="hidden"
              animate={isInView || hasPlayed ? 'show' : 'hidden'}
              variants={container}
              className="zigzag-content lg:pt-0 pt-30 fade-ani"
            >
              {module?.heading && (
                <motion.div variants={item} className="title-black">
                  <h3>{module.heading}</h3>
                </motion.div>
              )}
              {module?.description && (
                <motion.div variants={item} className="content global-list ">
                  {parse(module.description)}
                </motion.div>
              )}
              <motion.div
                variants={item}
                className="btn-custom flex flex-wrap items-center justify-start space-x-8 mt-30 relative z-9"
              >
                {module?.button?.title && (
                  <Link
                    to={module.button.url || '#'}
                    className="button header-btn-red cursor-pointer"
                  >
                    {module.button.title}
                  </Link>
                )}
                {module?.link?.title && (
                  <Link
                    to={module.link.url || '#'}
                    className="button btn-red-border"
                  >
                    {module.link.title}
                  </Link>
                )}
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderImageWithContent;
export const SliderImageWithContentFragment = graphql`
  fragment SliderImageWithContentFragment on WpTrip_Tripcontent_TripContent_SliderImageWithContent {
    id
    extraClass
    heading
    backgroundStyle
    button {
      target
      title
      url
    }
    description
    link {
      target
      title
      url
    }
    images {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
    }
  }
`;

export const ExperienceGuestFeedbacksFragment = graphql`
  fragment ExperienceSliderImageWithContentFragment on WpExperience_Experiencecontent_Experience_SliderImageWithContent {
    id
    extraClass
    heading
    backgroundStyle
    button {
      target
      title
      url
    }
    description
    link {
      target
      title
      url
    }
    images {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
    }
  }
`;
