import ImageOpt from './ImageOpt';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';

const customStylesBox = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: '0',
    borderRadius: '0',
    padding: '0',
    width: '100%',
    height: '100%',
    zindex: '999',
  },
};

const TripBreakdownTabsContent = ({ tabHeading, tabsData }) => {
  const [popupContent, setPopupContent] = useState(null);
  const [isPopupOpen, setPopupOpenStatus] = useState(false);

  const handleNextClickStay = () => {
    const swiper = document.querySelector(
      '.guest-review-content .swiper'
    ).swiper;
    swiper.slideNext();
  };

  const openPopupContent = (content) => {
    document.body.classList.add('video-modal-open');
    setPopupContent(content);
    setPopupOpenStatus(true);
  };

  const closePopup = () => {
    setPopupOpenStatus(false);
    document.body.classList.remove('video-modal-open');
  };

  const renderTabContent = () => {
    switch (tabHeading) {
      case 'TRIP SUMMARY': {
        return (
          <div
            id="trip-summary"
            className="tab-content trip-summary-content global-list"
          >
            <div className="icon-block">
              <div className="flex flex-wrap gap-y-14 items-start">
                {tabsData?.content?.data &&
                  tabsData.content.data.map((tab) => (
                    <div
                      key={uuidv4()}
                      className="icon-block-grid flex items-start fade-ani w-full"
                    >
                      <div className="icon">
                        <GatsbyImage
                          image={getImage(tab.icon)}
                          alt={tab.icon?.altText}
                        />
                      </div>
                      <div className="icon-content pl-30">
                        {tab?.heading && (
                          <div className="title-black">
                            <h5>{tab?.heading}</h5>
                          </div>
                        )}
                        {tab?.description && (
                          <div className="content ">
                            {parse(tab.description)}
                          </div>
                        )}
                        {tab?.button?.title && (
                          <div className=" btn-custom flex flex-wrap items-center space-x-8 mt-15 ">
                            <button
                              className="button btn-red-border"
                              onClick={() => openPopupContent(tab.popupContent)}
                            >
                              {tab.button.title}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        );
      }

      case 'INCLUDES & EXCLUDES': {
        return (
          <div id="includes-excludes" className="tab-content global-list">
            <div className="icon-block no-border">
              <div className="flex flex-wrap gap-y-14 items-start">
                {tabsData?.content?.data &&
                  tabsData.content.data.map((tab) => (
                    <div
                      key={uuidv4()}
                      className="icon-block-grid flex items-start fade-ani w-full"
                    >
                      <div className="icon">
                        <GatsbyImage
                          image={getImage(tab.icon)}
                          alt={tab.icon?.altText}
                        />
                      </div>
                      <div className="icon-content pl-30">
                        {tab?.heading && (
                          <div className="title-black">
                            <h5>{tab.heading}</h5>
                          </div>
                        )}
                        {tab?.description && (
                          <div className="content global-list ">
                            {parse(tab.description)}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        );
      }

      case 'ACCOMMODATION': {
        return (
          <div id="accommodation" className="tab-content global-list">
            <div className="content-slider-grid gap-y-16 grid grid-cols-1 ">
              {tabsData?.content?.data &&
                tabsData.content.data.map((tab) => (
                  <div
                    key={uuidv4()}
                    className="flex flex-wrap lgscreen:flex-col-reverse fade-ani"
                  >
                    <div className="lg:w-5/12 w-full lgscreen:mt-20">
                      {tab?.preHeading && (
                        <span className="text-red-300 uppercase text-15 mb-15 inline-block w-full">
                          {tab.preHeading}
                        </span>
                      )}
                      {tab?.heading && (
                        <div className="title-black">
                          <h5>{tab.heading}</h5>
                        </div>
                      )}
                      {tab?.description && (
                        <div className="content ">{parse(tab.description)}</div>
                      )}
                      {tab?.button?.title && (
                        <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20 ">
                          <button
                            onClick={() => openPopupContent(tab.popupContent)}
                            className="button btn-red-border"
                          >
                            {tab.button.title}
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="lg:w-7/12 w-full">
                      <div className="img-slider lg:pl-80">
                        <Swiper
                          spaceBetween={0}
                          loop={true}
                          slidesPerView={1}
                          centeredSlides={false}
                          allowTouchMove={true}
                          speed={400}
                          modules={[Autoplay, Pagination, Navigation]}
                          className="trip-slider"
                          breakpoints={{
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                            },
                            1024: {
                              slidesPerView: 1,
                              spaceBetween: 0,
                            },
                          }}
                        >
                          {tab?.images?.length > 0 &&
                            tab.images.map((image) => (
                              <SwiperSlide key={uuidv4()}>
                                <ImageOpt
                                  imgData={image.mediaItemUrl}
                                  width={570}
                                  height={490}
                                  imgClass="w-full"
                                  imgAlt={image?.altText}
                                />
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        );
      }

      case 'TRIP HIGHLIGHTS': {
        return (
          <div className="icon-block no-border">
            {tabsData?.content?.data &&
              tabsData.content.data.map((tab) => (
                <div
                  key={uuidv4()}
                  className="flex flex-wrap gap-y-14 items-start"
                >
                  <div className="icon-block-grid flex items-start fade-ani">
                    <div className="icon">
                      <GatsbyImage
                        image={getImage(tab.icon)}
                        alt="Timeless Africa Safaris image"
                      />
                    </div>
                    <div className="icon-content pl-30">
                      {tab?.heading && (
                        <div className="title-black">
                          <h5>{tab.heading}</h5>
                        </div>
                      )}
                      {tab?.description && (
                        <div className="content global-list ">
                          {parse(tab.description)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        );
      }

      case 'GUEST REVIEW': {
        return (
          <div id="guest-review" className="tab-content global-list">
            {tabsData?.content?.data &&
              tabsData.content.data.map((tab) => (
                <div key={uuidv4()} className="guest-review-content">
                  <div className="gr-title flex items-center fade-ani">
                    <GatsbyImage
                      image={getImage(tab.icon)}
                      alt={tab?.icon?.altText}
                    />
                    {tab?.heading && (
                      <div className="title-black pl-20">
                        <h5>{tab.heading}</h5>
                      </div>
                    )}
                  </div>
                  <div className="gr-info pl-50 pt-20">
                    {tab?.review && (
                      <h6 className="!normal-case">{tab.review}</h6>
                    )}
                    {tab?.author && (
                      <span className="uppercase mt-15 text-14 inline-block ">
                        {tab.author}
                      </span>
                    )}
                  </div>
                  <div className="gr-slider pl-50 mt-30 relative ">
                    <Swiper
                      spaceBetween={15}
                      loop={true}
                      slidesPerView={2}
                      allowTouchMove={true}
                      grabCursor={true}
                      speed={1000}
                      navigation={false}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="trip-slider"
                    >
                      {tab?.images?.length > 0 &&
                        tab.images.map((image) => (
                          <SwiperSlide key={uuidv4()}>
                            <GatsbyImage
                              image={getImage(image)}
                              alt={image.altText}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="swiper-arrow swiper-arrow-red absolute top-50-per translate-y-minus_50 right-[-70px] lgscreen:right-20 mdscreen:right-20 z-9">
                      <button
                        aria-label="Next"
                        className="button-next cursor-pointer"
                        onClick={handleNextClickStay}
                      >
                        <img
                          src="/images/white-arrow.webp"
                          loading="lazy"
                          alt="Timeless Africa Safaris image"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="content-bx bg-pattern p-50 mdscreen:p-25 mt-50 hidden">
                    <div className="flex flex-wrap items-center">
                      <div className="md:w-3/12 w-full">
                        <GatsbyImage
                          image={getImage(tab?.note?.image)}
                          alt={tab?.note?.image?.altText}
                        />
                      </div>
                      <div className="md:w-9/12 w-full">
                        <div className="content-bx-info content md:px-50 mdscreen:mt-25">
                          {tab?.note?.heading && <h6>{tab.note.heading}</h6>}
                          <div className="">
                            {tab?.note?.description &&
                              parse(tab?.note?.description)}
                          </div>
                          {tab?.note?.button?.title && (
                            <div className="btn-custom mt-30 relative z-9">
                              <Link
                                to={tab.note.button.url || '#'}
                                className="button header-btn-red"
                              >
                                {tab.note.button.title}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  return (
    <>
      {renderTabContent()}
      <Modal
        isOpen={isPopupOpen}
        onRequestClose={closePopup}
        style={customStylesBox}
      >
        <div className="zigzag-modal-popup bg-pattern2">
          <button
            onClick={closePopup}
            className="absolute right-30 top-30 w-50 h-50 bg-white rounded-999 inline-block z-99"
          >
            <img
              src="/images/modal-close.png"
              loading="lazy"
              className="m-auto w-20 h-20"
              alt="Close"
            />
          </button>
          <div className="flex flex-wrap items-center">
            <div className={`lg:w-5/12 w-full`}>
              <div className="img-slider">
                <Swiper
                  spaceBetween={0}
                  loop={false}
                  slidesPerView={1}
                  allowTouchMove={true}
                  grabCursor={true}
                  speed={1000}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="slider mdscreen:h-[90vw]"
                >
                  {popupContent?.images?.map((image) => (
                    <SwiperSlide key={uuidv4()}>
                      <ImageOpt
                        imgData={image.mediaItemUrl}
                        width={600}
                        height={800}
                        imgClass="w-full"
                        imgAlt={image.altText}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="lg:w-7/12 w-full">
              <div className="zigzag-modal-popup-content lg:px-100 px-30 lg:pt-0 py-30">
                {popupContent?.preHeading && (
                  <span className="text-red-300 uppercase text-15 mb-15 inline-block w-full">
                    {popupContent?.preHeading}
                  </span>
                )}
                {popupContent?.heading && (
                  <div className="title-black">
                    <h3>{popupContent?.heading}</h3>
                  </div>
                )}
                {popupContent?.description && (
                  <div className="content global-list ">
                    {parse(popupContent.description)}
                  </div>
                )}
                {popupContent?.button && (
                  <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30 relative z-9 ">
                    <Link
                      to={popupContent.button?.url}
                      target={popupContent.button?.target}
                      className="button header-btn-red"
                    >
                      <span>{popupContent.button?.title}</span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TripBreakdownTabsContent;
