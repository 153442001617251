import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useEffect, useRef, useState } from 'react';
import { Autoplay, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';

const RelatedTripInspiration = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.9,
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const handleNextClickStay = () => {
    const swiper = document.querySelector('.trip-slider .swiper').swiper;
    swiper.slideNext();
  };

  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.trip-slider .swiper').swiper;
    swiper.slidePrev();
  };

  useEffect(() => {
    imgCdn();
  }, []);

  const imgCdn = (imgData, width, height) => {
    const img = `https://wsrv.nl/?url=${imgData}&w=${width}&h=${height}&fit=cover&output=webp`;
    return img;
  };

  const makeBold = (item) => {
    let keyword = item;
    if (
      item === 'South Africa' ||
      item === 'Namibia' ||
      item === 'Botswana' ||
      item === 'Mozambique' ||
      item === 'Madagascar' ||
      item === 'Mauritius' ||
      item === 'Seychelles' ||
      item === 'Uganda' ||
      item === 'Tanzania' ||
      item === 'Rwanda' ||
      item === 'Kenya' ||
      item === 'Republic of Congo' ||
      item === 'Zambia' ||
      item === 'Zimbabwe'
    ) {
      const re = new RegExp(keyword, 'g');
      const boldItem = item
        .replace(
          re,
          "<span className='font-800 text-red-300'>" + keyword + '</span>'
        )
        .toString();

      return boldItem;
    } else {
      const re = new RegExp(keyword, 'g');
      const normalItem = item
        .replace(re, '<span>' + keyword + '</span>')
        .toString();
      return normalItem;
    }
  };

  return (
    <section
      className={`trip-slider lg:py-60 py-30 ${
        module?.backgroundStyle === 'background with pattern'
          ? ' bg-pattern bg-pattern-spacing'
          : ''
      }${module?.extraClass ? ' ' + module.extraClass : ''}`}
    >
      <div className="md:w-8/12 w-full m-auto">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="trip-slider-top text-center px-20"
        >
          {module?.heading && (
            <motion.div variants={item} className="title-black">
              <h3>{module.heading}</h3>
            </motion.div>
          )}
          {module?.description && (
            <motion.div
              variants={item}
              className="content w-[474px] smscreen2:w-full smscreen2:px-20 m-auto "
            >
              {parse(module.description)}
            </motion.div>
          )}
          {module?.link && (
            <motion.div
              variants={item}
              className="btn-custom flex flex-wrap items-center justify-center space-x-8 mt-30 lg:mb-20 mb-0 "
            >
              <Link
                to={module.link?.url}
                target={module?.link?.target}
                className="button btn-border"
              >
                <span>{module.link?.title}</span>
                <img
                  src="/images/btn-red-bg.webp"
                  loading="lazy"
                  alt="Timeless Africa Safaris image"
                />
              </Link>
            </motion.div>
          )}
        </motion.div>
      </div>
      {module?.trip?.length > 0 && (
        <motion.div
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container2}
          id="trip-slider-row"
          className="trip-slider-row trip-drag-section trip-slider-grid lg:mt-50 mt-25 fade-ani relative overflow-hidden"
        >
          <Swiper
            className="h-full"
            loop={true}
            lazy={{
              loadPrevNext: true,
            }}
            slidesPerView={2.3}
            spaceBetween={20}
            centeredSlides={true}
            allowTouchMove={true}
            speed={1000}
            pagination={{
              el: '.trip-slider-pagination',
              type: 'progressbar',
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Scrollbar]}
            breakpoints={{
              100: {
                slidesPerView: 1.3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2.3,
                spaceBetween: 20,
              },
            }}
          >
            {module.trip.map(({ tripContent, ...item }) => (
              <SwiperSlide key={uuidv4()}>
                <Link to={item?.uri} className="hover:cursor-grab">
                  {item?.featuredImage?.node && (
                    <img
                      src={imgCdn(
                        item?.featuredImage?.node?.mediaItemUrl,
                        615,
                        410
                      )}
                      loading="lazy"
                      alt={item?.featuredImage?.node?.altText}
                      className="w-full"
                      width="615"
                      height="410"
                    />
                  )}
                </Link>
                <div className="trip-slider-content pt-30 lg:px-10 px-10">
                  <div className="trip-top-info flex ipad:block items-center justify-between mb-15">
                    <h5>
                      {tripContent?.meta?.totalNights}
                      <span>{tripContent?.meta?.price}</span>
                    </h5>
                    <h6 className="pt-5 ipad:!text-left">
                      Originally curated for
                      <span className="dest-item">
                        {tripContent?.meta?.curatedFor}
                      </span>
                    </h6>
                  </div>
                  <div className="trip-slider-content-inner flex flex-wrap mdscreen:flex-col">
                    <div className="w-full mdscreen:order-2 pr-30 lgscreen:pr-15">
                      <div className="title-black ">
                        <Link to={item?.uri}>
                          <h5>{item.title}</h5>
                        </Link>
                      </div>
                      <div className="content flex mt-5 ">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="text-red-300 fa-map-marker-alt"
                        />
                        <div className="inline">
                          {tripContent?.meta?.location?.map((item, i) => (
                            <span className="dest-item" key={i}>
                              {parse(makeBold(item.title))}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="btn-custom flex flex-wrap items-start justify-start space-x-8 mt-20 ">
                        <Link to={item?.uri} className="button btn-border">
                          <span>Explore Trip Inspiration</span>
                          <img
                            src="/images/btn-red-bg.webp"
                            loading="lazy"
                            alt="Timeless Africa Safaris image"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-arrow swiper-arrow-red">
            <a
              className="button-prev cursor-pointer absolute top-[30%] left-[18vw] mdscreen:left-[55px] translate-y-minus_50 lgscreen:top-[17.5%] z-9"
              onClick={handlePrevClickStay}
            >
              <img
                src="/images/white-arrow.svg"
                loading="lazy"
                className="scale-x-[-1]"
                alt="Timeless Africa Safaris image"
              />
            </a>
            <a
              className="button-next cursor-pointer absolute top-[30%] lgscreen:top-[17.5%] right-[18vw]  mdscreen:right-[55px] translate-y-minus_50 z-9"
              onClick={handleNextClickStay}
            >
              <img
                src="/images/white-arrow.svg"
                loading="lazy"
                alt="Timeless Africa Safaris image"
              />
            </a>
          </div>
          <div className="trip-slider__bottom-bar mt-10 mdscreen:mt-0 px-70 lgscreen:px-30">
            <div className="trip-slider__scrollbar relative flex-1 overflow-hidden left-0 bottom-0 h-[2px]">
              {/* <!-- swiper progressbar --> */}
              <div className="slider-progressbar trip-slider-pagination swiper-pagination"></div>
            </div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default RelatedTripInspiration;
export const RelatedTripInspirationFragment = graphql`
  fragment RelatedTripInspirationFragment on WpPage_Pagecontent_PageContent_RelatedTripInspiration {
    id
    description
    extraClass
    backgroundStyle
    heading
    hideSection
    link {
      target
      title
      url
    }
    trip {
      ... on WpTrip {
        id
        uri
        title
        featuredImage {
          node {
            id
            mediaItemUrl
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 500
            )
            altText
          }
        }
        tripContent {
          meta {
            totalNights
            price
            curatedFor
            location {
              ... on WpDestination {
                id
                title
              }
            }
            features {
              icon
              name
            }
            curatedBy {
              ... on WpTeam {
                id
                title
                uri
              }
            }
          }
        }
      }
    }
  }
`;

export const ExperienceSingleRelatedTripInspirationFragment = graphql`
  fragment ExperienceSingleRelatedTripInspirationFragment on WpExperience_Experiencecontent_Experience_RelatedTripInspiration {
    id
    extraClass
    hideSection
    heading
    backgroundStyle
    link {
      title
      url
      target
    }
    description
    trip {
      ... on WpTrip {
        id
        uri
        title
        featuredImage {
          node {
            id
            mediaItemUrl
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 500
            )
            altText
          }
        }
        tripContent {
          meta {
            totalNights
            price
            curatedFor
            location {
              ... on WpDestination {
                id
                title
              }
            }
            features {
              icon
              name
            }
            curatedBy {
              ... on WpTeam {
                id
                title
                uri
              }
            }
          }
        }
      }
    }
  }
`;

export const TravelStyleSingleRelatedTripInspirationFragment = graphql`
  fragment TravelStyleSingleRelatedTripInspirationFragment on WpTravel_Travelstylecontent_TravelStyle_RelatedTripInspiration {
    id
    extraClass
    hideSection
    heading
    trip {
      ... on WpTrip {
        id
        uri
        title
        featuredImage {
          node {
            id
            mediaItemUrl
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 500
            )
            altText
          }
        }
        tripContent {
          meta {
            totalNights
            price
            curatedFor
            location {
              ... on WpDestination {
                id
                title
              }
            }
            features {
              icon
              name
            }
            curatedBy {
              ... on WpTeam {
                id
                title
                uri
              }
            }
          }
        }
      }
    }
  }
`;

export const TripSingleRelatedTripInspirationFragment = graphql`
  fragment TripSingleRelatedTripInspirationFragment on WpTrip_Tripcontent_TripContent_RelatedTripInspiration {
    id
    extraClass
    hideSection
    heading
    trip {
      ... on WpTrip {
        id
        uri
        title
        featuredImage {
          node {
            id
            mediaItemUrl
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 500
            )
            altText
          }
        }
        tripContent {
          meta {
            totalNights
            price
            curatedFor
            location {
              ... on WpDestination {
                id
                title
              }
            }
            features {
              icon
              name
              featureIcon {
                altText
                mediaItemUrl
              }
            }
            curatedBy {
              ... on WpTeam {
                id
                title
                uri
              }
            }
          }
        }
      }
    }
  }
`;

export const TeamSingleRelatedTripInspirationFragment = graphql`
  fragment TeamSingleRelatedTripInspirationFragment on WpTeam_Teamcontent_TeamContent_RelatedTripInspiration {
    id
    extraClass
    hideSection
    heading
    backgroundStyle
    trip {
      ... on WpTrip {
        id
        uri
        title
        featuredImage {
          node {
            id
            mediaItemUrl
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 500
            )
            altText
          }
        }
        tripContent {
          meta {
            totalNights
            price
            curatedFor
            location {
              ... on WpDestination {
                id
                title
              }
            }
            features {
              icon
              name
            }
            curatedBy {
              ... on WpTeam {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

export const MonthlyEventRelatedTripInspirationFragment = graphql`
  fragment MonthlyEventRelatedTripInspirationFragment on WpPage_Pagecontent_PageContent_MonthlyEvents_months_events_Content_RelatedTripInspiration {
    id
    extraClass
    hideSection
    heading
    trip {
      ... on WpTrip {
        id
        uri
        title
        featuredImage {
          node {
            id
            mediaItemUrl
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 500
            )
            altText
          }
        }
        tripContent {
          meta {
            totalNights
            price
            curatedFor
            location {
              ... on WpDestination {
                id
                title
              }
            }
            features {
              icon
              name
            }
            curatedBy {
              ... on WpTeam {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;
