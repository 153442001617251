import { graphql } from "gatsby";
import React from "react";
import Banner from "../components/Banner"
import IconWithCaption from "../components/IconWithCaption"
import SliderImageWithContent from "../components/SliderImageWithContent"
import GuestFeedbacks from "../components/GuestFeedbacks"
import TripBreakdown from "../components/TripBreakdown"
import RelatedTripInspiration from "../components/RelatedTripInspiration"

const Trip = ({ modules, location }) => {
  const components = {
    Banner,
    IconWithCaption,
    SliderImageWithContent,
    GuestFeedbacks,
    TripBreakdown,
    RelatedTripInspiration
  }

  return (
    <>
      {modules
        ? modules.filter((module) => !module?.hideSection || module?.hideSection === "no").map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpTrip_Tripcontent_TripContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                location
              })
            );
          })
        : null}
    </>
  );
}

export default Trip;
export const TripModulesFragment = graphql`
  fragment TripModulesFragment on WpTrip {
    tripContent {
      tripContent {
        __typename
        ...TripBannerFragment
        ...IconWithCaptionFragment
        ...SliderImageWithContentFragment
        ...TripSingleGuestFeedbacksFragment
        ...TripBreakdownFragment
        ...TripSingleRelatedTripInspirationFragment
      }
    }
  }
`;