import TripBreakdownTabsContent from './TripBreakdownTabsContent';
import { motion, useInView } from 'framer-motion';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const TripBreakdown = ({ module }) => {
  const [tabOption, setTabOption] = useState(module?.tabs?.[0].heading || '');
  const ref = useRef(null);
  const ref2 = useRef(null);
  const isInView = useInView(ref2);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
        delay: 1.1,
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const onTabClick = (heading) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    setTabOption(heading);
  };

  const query = useStaticQuery(graphql`
    query {
      team: allWpTeam {
        edges {
          node {
            id
            title
            uri
          }
        }
      }
    }
  `);

  const teamMember = query.team.edges
    .filter((item) => item.node.title == module.curatedBy.trim())
    .map((item1) => item1.node.uri);

  return (
    <>
      <section
        className={`trip-single-tabs lg:py-120 py-60${
          module?.extraClass ? ' ' + module.extraClass : ''
        }`}
        ref={ref}
      >
        <div className="container-fluid px-[125px] mdscreen:px-20">
          <div className="flex flex-wrap justify-between">
            <motion.div
              ref={ref2}
              initial="hidden"
              animate={isInView || hasPlayed ? 'show' : 'hidden'}
              variants={container}
              className="section-title fade-ani"
            >
              {module?.heading && (
                <motion.div variants={item} className="title-black">
                  <h3>{module.heading}</h3>
                </motion.div>
              )}
              {module?.description && (
                <motion.div variants={item} className="content ">
                  <p>{module.description}</p>
                </motion.div>
              )}
              {module?.curatedBy && (
                <motion.div variants={item} className="sub-content mt-10 ">
                  <p>
                    Curated by
                    {teamMember.length > 0 ? (
                      <Link to={teamMember[0]} className="ml-5">
                        <span>{module.curatedBy}</span>
                      </Link>
                    ) : (
                      <span>{module.curatedBy}</span>
                    )}
                  </p>
                </motion.div>
              )}
            </motion.div>
            <div className="section-title-img fade-ani">
              <GatsbyImage
                image={getImage(module.image)}
                alt={module.image?.altText}
              />
            </div>
          </div>

          <div className="trip-container mt-50 lgscreen:mt-20">
            <motion.div
              initial="hidden"
              animate={isInView || hasPlayed ? 'show' : 'hidden'}
              variants={container2}
              className="flex flex-wrap"
            >
              <div className="lg:w-2/12 w-full fade-ani">
                <div className="left-tabs border-r-1 border-gray-300 py-30 lgscreen:border-0">
                  <div className="tabs-horizontal">
                    <ul className="tabs">
                      {module?.tabs.length > 0 &&
                        module?.tabs.map((tab) => (
                          <li
                            key={uuidv4()}
                            onClick={() => onTabClick(tab.heading)}
                            className={`tab-link cursor-pointer mdscreen:!mb-0 ${
                              tab.heading === 'MAP ROUTE' ? '!hidden' : ''
                            } ${
                              tab.heading === 'MAP ROUTES ' ? '!hidden' : ''
                            } ${tab.heading === 'Map Route' ? '!hidden' : ''} ${
                              tab.heading === 'MAP ROUTE ' ? '!hidden' : ''
                            } ${tabOption == tab.heading ? 'tab-current' : ''}`}
                          >
                            {' '}
                            <span>{tab.heading}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="price-info mt-30 lgscreen:mt-10 border-t-1 border-gray-300 pt-30 mdscreen:pt-0 inline-block w-90-per lgscreen:w-full">
                    {module?.pricePreHeading && (
                      <p className="uppercase text-12 mdscreen:hidden">
                        {module.pricePreHeading}
                      </p>
                    )}
                    {module?.price && (
                      <h6 className="text-red-300 uppercase text-18 font-body mdscreen:hidden">
                        {module.price?.trim()}
                      </h6>
                    )}
                    {module?.priceNote && (
                      <span className="text-[9px] text-black-200 leading-[12px] inline-block mt-10 mdscreen:hidden">
                        {module.priceNote}
                      </span>
                    )}
                    {module?.button?.title && (
                      <div className="btn-custom lg:mt-30 mt-15 mdscreen:hidden">
                        <Link
                          to={module.button.url || '/guest-enquiry/'}
                          className="button header-btn-red mdscreen:hidden"
                        >
                          {module.button.title}
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {module?.tabs.length > 0 &&
                module?.tabs.map(
                  (tab) =>
                    tabOption == tab.heading && (
                      <div
                        key={uuidv4()}
                        className="lg:w-10/12 w-full fade-ani"
                      >
                        <div className="tabs-container mt-20 mdscreen:mt-0 lg:pl-50 lg:pr-0 laptop:pr-0 laptop:pl-50 lgscreen:pl-0 lgscreen:pr-0">
                          <TripBreakdownTabsContent
                            tabHeading={tab.heading.trim()}
                            tabsData={tab}
                          />
                        </div>
                      </div>
                    )
                )}

              <div className="price-info mt-30 border-t-1 border-gray-300 pt-30 hidden mdscreen:inline-block  w-90-per lgscreen:w-full">
                {module?.pricePreHeading && (
                  <p className="uppercase text-12">{module.pricePreHeading}</p>
                )}
                {module?.price && (
                  <h6 className="text-red-300 uppercase text-18 font-body">
                    {module.price?.trim()}
                  </h6>
                )}
                {module?.priceNote && (
                  <span className="text-[9px] text-black-200 leading-[12px] inline-block mt-10">
                    {module.priceNote}
                  </span>
                )}
                {module?.button?.title && (
                  <div className="btn-custom lg:mt-30 mt-15">
                    <Link
                      to={module.button.url || '/guest-enquiry/'}
                      className="button header-btn-red"
                    >
                      {module.button.title}
                    </Link>
                  </div>
                )}
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TripBreakdown;
export const TripBreakdownFragment = graphql`
  fragment TripBreakdownFragment on WpTrip_Tripcontent_TripContent_TripBreakdown {
    id
    hideSection
    extraClass
    heading
    description
    curatedBy
    price
    priceNote
    pricePreHeading
    image {
      altText
      mediaItemUrl
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
    }
    button {
      target
      title
      url
    }
    tabs {
      heading
      content {
        type
        data {
          heading
          description
          author
          preHeading
          review
          button {
            target
            title
            url
          }
          icon {
            altText
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
          }
          images {
            altText
            mediaItemUrl
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
          }
          locations {
            name
          }
          map {
            altText
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
          }
          note {
            heading
            description
            image {
              altText
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
            }
            button {
              target
              title
              url
            }
          }
          popupContent {
            preHeading
            heading
            description
            button {
              target
              title
              url
            }
            images {
              altText
              mediaItemUrl
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
            }
          }
        }
      }
    }
  }
`;
